import React, { useState, useEffect, useRef } from "react";
import {
  ChatMessage,
  WebSocketMessage,
  ChatButton as ChatButtonType,
  ChatList as ChatListType,
} from "@/app/types/chat";
import { sendMessage } from "@/app/utils/chatbot";
import { BRAND_NAMES } from "@/app/utils/utils";

interface ChatUIProps {
  phoneNumber: string;
  isMinimized: boolean;
  keepAlive?: boolean;
}

export default function ChatUI({
  phoneNumber,
  isMinimized,
  keepAlive = true,
}: ChatUIProps) {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState("");
  const [inputType, setInputType] = useState<"text" | "plate">("text");
  const [hasOptions, setHasOptions] = useState(false);
  const [inputBlocked, setInputBlocked] = useState(false);

  const wsRef = useRef<WebSocket | null>(null);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout>();
  const processedMessageIds = useRef<Set<string>>(new Set());
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const isInitialConnection = useRef(true);

  // Função atualizada para detectar o tipo de input
  const detectInputType = (message: string) => {
    // Array de mensagens que devem ativar o input de placa
    const plateMessages = [
      "digite a placa do seu veículo",
      "*Fique tranquilo que seus dados estão protegidos*",
      "Digite a placa do veículo",
      "Ops, a placa digitada é inválida",
    ];

    // Verifica se a mensagem contém qualquer um dos textos que ativam o input de placa
    const shouldUsePlateInput = plateMessages.some((text) =>
      message.toLowerCase().includes(text.toLowerCase())
    );

    return shouldUsePlateInput ? "plate" : "text";
  };

  // Effect para detectar o tipo de input baseado na última mensagem
  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.fromMe) {
        setInputType(detectInputType(lastMessage.body));
      }
    }
  }, [messages]);

  useEffect(() => {
    if (!phoneNumber || wsRef.current?.readyState === WebSocket.OPEN) return;

    const connectWebSocket = () => {
      try {
        const apiKey = BRAND_NAMES.CHAT_API_KEY;
        const formattedPhone = phoneNumber.replace(/\D/g, "");
        const wsUrl = `wss://atendimentogab.quasix.com.br/ws/chatbot-site/${formattedPhone}/?apiKey=${apiKey}`;

        setConnectionStatus(`Conectando...`);
        setIsConnecting(true);

        const ws = new WebSocket(wsUrl);
        wsRef.current = ws;

        ws.onopen = () => {
          console.log("WebSocket conectado com sucesso");
          setIsConnected(true);
          setIsConnecting(false);
          setConnectionStatus("");

          // Envia mensagem inicial apenas na primeira conexão
          if (isInitialConnection.current) {
            sendMessage("novo atendimento", phoneNumber);
            isInitialConnection.current = false;
          }
        };

        ws.onmessage = (event) => {
          try {
            const data = JSON.parse(event.data);
            if (data.type === "chat_mensagem" && data.mensagem) {
              // Não mostra a mensagem "novo atendimento"
              if (data.mensagem.body === "novo atendimento") {
                return;
              }

              if (!processedMessageIds.current.has(data.mensagem.id)) {
                processedMessageIds.current.add(data.mensagem.id);
                setMessages((prev) => [...prev, data.mensagem]);

                // Detecta tipo de input se necessário
                if (data.mensagem.fromMe) {
                  setInputType(detectInputType(data.mensagem.body));
                }
              }
            }
          } catch (error) {
            console.error("Erro ao processar mensagem:", error);
          }
        };

        ws.onclose = () => {
          setIsConnected(false);
          wsRef.current = null;
        };

        ws.onerror = () => {
          setIsConnected(false);
          wsRef.current = null;
        };
      } catch (error) {
        console.error("Erro ao criar WebSocket:", error);
        setConnectionStatus("Erro na conexão");
        setIsConnecting(false);
      }
    };

    connectWebSocket();

    return () => {
      if (!keepAlive && wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
    };
  }, [phoneNumber, keepAlive]);

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();

    let messageToSend = inputMessage.trim();

    // Se for uma placa, remove o hífen antes de enviar
    if (inputType === "plate") {
      messageToSend = messageToSend.replace(/-/g, "");
    }

    if (!messageToSend) return;

    try {
      await sendMessage(messageToSend, phoneNumber);
      setInputMessage("");
    } catch (error) {
      console.error("Erro ao enviar mensagem:", error);
    }
  };

  const renderButtons = (buttons: ChatButtonType[]) => (
    <div className="mt-2 space-y-2 max-w-full">
      {buttons.map((button) => (
        <button
          key={button.id}
          onClick={() => {
            sendMessage(button.title, phoneNumber);
          }}
          className="w-full p-2 text-sm bg-white text-gray-800 rounded shadow hover:bg-gray-50 border border-gray-200 transition-colors break-words whitespace-normal"
        >
          {button.title}
        </button>
      ))}
    </div>
  );

  const renderLists = (lists: ChatListType[]) => (
    <div className="mt-2 max-w-full">
      {lists.map((list, index) => (
        <div key={index} className="mb-2">
          <p className="font-bold text-inherit mb-2 break-words">
            {list.title}
          </p>
          <div className="space-y-1">
            {list.rows.map((row) => (
              <button
                key={row.id}
                onClick={() => {
                  sendMessage(row.title, phoneNumber);
                }}
                className="w-full p-2 text-sm bg-white text-gray-800 rounded shadow hover:bg-gray-50 border border-gray-200 transition-colors break-words whitespace-normal"
              >
                {row.title}
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  // Função para verificar se a mensagem deve bloquear o input
  const shouldBlockInput = (message: string) => {
    const blockingMessages = [
      "Poxa, não conseguimos encontrar sua placa em nosso sistema",
      "Por favor, aguarde um momento enquanto verificamos as informações",
    ];

    return blockingMessages.some((text) =>
      message.toLowerCase().includes(text.toLowerCase())
    );
  };

  // Effect para controlar o bloqueio do input baseado nas mensagens
  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.fromMe) {
        setInputBlocked(shouldBlockInput(lastMessage.body));
      }
    }
  }, [messages]);

  const renderInput = () => {
    if (inputType === "plate") {
      return (
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => {
            const value = e.target.value.toUpperCase();
            const cleanValue = value.replace(/[^A-Z0-9]/g, "");

            if (cleanValue.length <= 3) {
              setInputMessage(cleanValue);
            } else {
              const formatted = `${cleanValue.slice(0, 3)}-${cleanValue.slice(
                3,
                7
              )}`;
              setInputMessage(formatted);
            }
          }}
          placeholder={inputBlocked ? "Aguarde..." : "ABC-1234 ou ABC-1D23"}
          maxLength={8}
          className="w-full p-3 border text-black border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-transparent transition-all disabled:bg-gray-100 disabled:text-gray-500"
          disabled={!isConnected || inputBlocked || hasOptions}
        />
      );
    }

    return (
      <input
        type="text"
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        placeholder={inputBlocked ? "Aguarde..." : "Digite sua mensagem..."}
        className="w-full p-3 border text-black border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-transparent transition-all disabled:bg-gray-100 disabled:text-gray-500"
        disabled={!isConnected || inputBlocked || hasOptions}
      />
    );
  };

  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      const hasButtonsOrLists = !!(
        (lastMessage.buttons && lastMessage.buttons.length > 0) ||
        (lastMessage.lists && lastMessage.lists.length > 0)
      );
      setHasOptions(hasButtonsOrLists);
    }
  }, [messages]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto px-4 py-6">
        {messages.map((message) => (
          <div
            key={message.id || `${message.time}-${Math.random()}`}
            className={`flex gap-1 items-start mb-4 ${
              !message.fromMe ? "flex-row-reverse" : ""
            }`}
          >
            <div
              className={`p-3 rounded-lg max-w-[80%] break-words whitespace-pre-line ${
                !message.fromMe
                  ? "bg-gray-500 text-white"
                  : "bg-gray-900 text-white"
              }`}
            >
              <div>{message.body}</div>
              {message.buttons &&
                message.buttons.length > 0 &&
                renderButtons(message.buttons)}
              {message.lists &&
                message.lists.length > 0 &&
                renderLists(message.lists)}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="p-4 border-t bg-white">
        <form onSubmit={handleSendMessage} className="flex flex-col gap-2">
          {renderInput()}
          <button
            type="submit"
            disabled={!isConnected || inputBlocked || hasOptions}
            className="w-full bg-red-600 text-white rounded-lg py-3 hover:bg-red-700 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            {inputBlocked ? "Aguarde..." : "Enviar"}
          </button>
        </form>
      </div>
    </div>
  );
}
