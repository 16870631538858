import { Flow } from "./types";

export const posVendaFlow: Flow = {
  totalSteps: 5,
  steps: {
    1: {
      message: "Olá! 👋 Que alegria ter você por aqui! Para começarmos, me diga seu NOME COMPLETO:",
      inputType: "text",
      validate: (value: string) => value.length > 0,
      errorMessage: "Ops! Parece que você esqueceu de digitar seu nome. Pode me informar?",
    },
    2: {
      message: (name: string) => `Perfeito! 📱 Agora, me passe seu número de TELEFONE com DDD:`,
      inputType: "phone",
      validate: (value: string) => value.replace(/\D/g, "").length === 11,
      errorMessage: "Hmm, esse número não parece estar correto. Pode tentar novamente?",
    },
    3: {
      message: "Excelente! ✉️ Agora preciso do seu E-MAIL para continuarmos:",
      inputType: "email",
      validate: (value: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
      },
      errorMessage: "Ops! Esse e-mail não parece estar correto. Pode verificar?",
    },
    4: {
      message: "Quase lá! 🔢 Agora me informe seu CPF (apenas números):",
      inputType: "cpf",
      validate: (value: string) => value.replace(/\D/g, "").length === 11,
      errorMessage: "Hmm, esse CPF não parece estar correto. Pode tentar novamente?",
    },
    5: {
      message: "Por fim, 🏢 qual unidade você prefere?",
      inputType: "dealerSelect",
      validate: (value: string) => value.length > 0,
      errorMessage: "Ops! Você precisa selecionar uma unidade para continuarmos.",
    },
  },
}; 