import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { ChatMessage, ChatConfig } from "../types/chat";
import { BRAND_NAMES } from "./utils";

const config: ChatConfig = {
  baseUrl: "https://atendimentogab.quasix.com.br",
  instanceId: BRAND_NAMES.CHAT_INSTANCE_ID,
  token: BRAND_NAMES.CHAT_API_KEY,
  phoneNumber: "", // Será preenchido dinamicamente
};

export const sendMessage = async (
  message: string,
  phoneNumber: string
): Promise<void> => {
  // Garante que o número sempre tenha 55 no início
  const formattedNumber = phoneNumber.startsWith("55")
    ? phoneNumber
    : `55${phoneNumber.replace(/\D/g, "")}`;

  const payload = {
    messages: [
      {
        id: uuidv4(),
        body: message || "iniciar",
        fromMe: false,
        self: 0,
        isForwarded: false,
        author: `${formattedNumber}@c.us`,
        time: Math.floor(Date.now() / 1000),
        chatId: `${formattedNumber}@c.us`,
        type: "chat",
        senderName: "",
        caption: null,
        quotedMsgId: null,
        chatName: formattedNumber,
      },
    ],
    instanceId: config.instanceId,
  };

  try {
    
    await axios.post('/api/chatbot', payload);
  } catch (error) {
    console.error("Erro ao enviar mensagem:", error);
    if (axios.isAxiosError(error)) {
      console.error('Detalhes do erro:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
    }
    throw error;
  }
};
