import React, { useState } from "react";
import { PatternFormat } from "react-number-format";
import ChatUI from "./ChatUI";

export default function ChatManager() {
  const [isVisible, setIsVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showChat, setShowChat] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [hasStartedChat, setHasStartedChat] = useState(false);

  const handlePhoneSubmit = (formattedNumber: string) => {
    setPhoneNumber(formattedNumber);
    setShowChat(true);
    setIsVisible(true);
    setHasStartedChat(true);
  };

  const handleMinimize = () => {
    setIsVisible(false);
    setIsMinimized(true);
  };

  return (
    <>
      {/* Chat sempre montado, apenas controlamos visibilidade */}
      <div
        className={`fixed z-[1000] lg:bottom-10 lg:right-10 transition-opacity duration-300 ${
          isVisible ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <main className="absolute lg:bottom-0 lg:right-0 flex flex-col lg:rounded-xl lg:w-[360px] lg:h-[450px] max-lg:w-screen max-lg:h-screen max-lg:fixed max-lg:top-0 max-lg:left-0 max-lg:right-0 max-lg:bottom-0 max-lg:z-[1000] shadow-[3px_4px_12px_rgba(0,0,0,0.15)] bg-white">
          {/* Header fixo */}
          <header className="flex gap-9 justify-between items-center p-4 w-full leading-snug bg-red-600 text-stone-50">
            <div className="flex gap-3 items-center self-stretch my-auto min-w-[240px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                />
              </svg>
              <div className="flex flex-col self-stretch my-auto">
                <h1 className="text-base font-semibold">Assistente Virtual</h1>
              </div>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/6f6988fb0ce3ac56ffc7af4c2b9df8644b88e3128e5b1d3f394d1660eec69eae"
              alt="Menu"
              className="object-contain shrink-0 self-stretch my-auto aspect-[1.03] w-[31px] cursor-pointer"
              onClick={handleMinimize}
            />
          </header>

          {/* Container principal com scroll */}
          <div className="flex flex-col-reverse flex-1 overflow-hidden">
            {showChat ? (
              <ChatUI
                phoneNumber={phoneNumber}
                isMinimized={isMinimized}
                keepAlive={true}
              />
            ) : (
              <div className="flex flex-col  p-4 overflow-y-auto">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const cleanNumber = phoneNumber.replace(/\D/g, "");
                    if (cleanNumber.length === 11) {
                      handlePhoneSubmit(`55${cleanNumber}`);
                    }
                  }}
                  className="space-y-4"
                >
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Digite seu número de telefone:
                    </label>
                    <PatternFormat
                      format="(##) #####-####"
                      value={phoneNumber}
                      onValueChange={({ value }) => setPhoneNumber(value)}
                      placeholder="(99) 99999-9999"
                      className="w-full p-3 border text-black border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-transparent transition-all"
                      mask="_"
                    />
                    <p className="mt-1 text-sm text-gray-500">
                      Digite apenas números, incluindo DDD
                    </p>
                  </div>
                  <button
                    type="submit"
                    disabled={phoneNumber.replace(/\D/g, "").length !== 11}
                    className="w-full bg-red-600 text-white rounded-lg py-3 hover:bg-red-700 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
                  >
                    Iniciar Chat
                  </button>
                </form>
              </div>
            )}
          </div>
        </main>
      </div>

      {/* Botão flutuante - só mostra o texto animado se nunca iniciou o chat */}
      {(!isVisible || isMinimized) && (
        <div className="flex flex-col fixed z-[1000] lg:bottom-10 lg:right-10 bottom-[calc(env(safe-area-inset-bottom)+20px)] right-5 w-[60px] h-[60px]">
          {!hasStartedChat && (
            <div className="absolute right-[34px] whitespace-normal">
              <div className="bg-red-600 w-40 font-bold text-white px-4 py-2 max-w-[160px] rounded-l-lg shadow-lg animate-slide-left text-sm leading-4 flex flex-col">
                <span>Venha fazer seu</span>
                <span>agendamento!</span>
              </div>
            </div>
          )}
          <button
            onClick={() => {
              setIsVisible(true);
              setIsMinimized(false);
            }}
            className="w-[60px] h-[60px] bg-red-600 rounded-full flex items-center justify-center cursor-pointer hover:scale-105 transition-transform shadow-lg z-10"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
              />
            </svg>
          </button>
        </div>
      )}
    </>
  );
}
