import { Header } from "@/app/components/LayoutComponents/Header";
import dynamic from "next/dynamic";
import { Contact } from "@/app/components/LayoutComponents/Contact";
import { LayoutFooter } from "@/app/components/LayoutComponents/Footer";
import { usePathname } from "next/navigation";
import ChatBot from "@/app/components/LayoutComponents/ChatBot";
import ChatManager from "../UI/ChatManager";

interface DefaultLayoutProps {
  children: React.ReactNode;
  contents?: string[];
  title_contents?: string[];
}

export default function DefaultLayout({
  children,
  contents,
  title_contents,
}: DefaultLayoutProps) {
  const pathname = usePathname();

  const isCMS = pathname?.includes("cms");
  const isTouchpoint = pathname?.includes("touchpoint");
  const isPosVenda = pathname?.includes("pos-venda");
  const isAgendamento = pathname?.includes("agendamento");

  const DynamicAccordion = dynamic(() => import("./Accordion"));

  return (
    <>
      {isCMS || isTouchpoint ? (
        <body>{children}</body>
      ) : (
        <body>
          <Header />
          {children}
          <Contact />
          <LayoutFooter />
          {!isCMS && contents && title_contents && (
            <>
              <DynamicAccordion
                contents={contents}
                title_contents={title_contents}
              />
            </>
          )}
          {!isCMS && !isPosVenda && !isAgendamento && <ChatBot />}
          {isPosVenda && <ChatManager />}
          {isAgendamento && <ChatManager />}
        </body>
      )}
    </>
  );
}
